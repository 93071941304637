import React, { useState, useEffect } from 'react';
import '../style/LoginForm.css';

const LoginForm = ({ onLoginSuccess }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  // Carrega as credenciais do localStorage se existirem
  useEffect(() => {
    const storedUsername = localStorage.getItem('username');
    const storedPassword = localStorage.getItem('password');
    if (storedUsername && storedPassword) {
      setUsername(storedUsername);
      setPassword(storedPassword);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://decalcinha-nodejs.vercel.app/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();
      if (response.ok && data.success) {
        setMessage('Login bem-sucedido!');
        // Salva as credenciais no localStorage
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);
        onLoginSuccess();
      } else {
        setMessage(data.message || 'Usuário ou senha incorretos.');
      }
    } catch (error) {
      console.error('Erro ao conectar com o servidor:', error);
      setMessage('Erro ao conectar com o servidor.');
    }
  };

  return (
    <div className="login-page">
      <div className="login-wrapper">
        <h2 className="login-title">Login De Calcinha</h2>
        <form className="login-container" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className="form-label">Usuário:</label>
            <input
              className="form-input"
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label className="form-label">Senha:</label>
            <input
              className="form-input"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button className="login-button" type="submit">Entrar</button>
        </form>
        {message && <p className="login-message">{message}</p>}
      </div>
    </div>
  );
};

export default LoginForm;
