import React from 'react';

const NavBar = ({ onLogout }) => {
    const handleLogout = () => {
        // Limpa as credenciais do localStorage
        localStorage.removeItem('username');
        localStorage.removeItem('password');
        // Chama a função de logout recebida via props
        onLogout();
    };

    return (
        <nav style={{
            width: '100%',
            backgroundColor: '#333',
            color: '#fff',
            padding: '10px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }}>
            <ul style={{ display: 'flex', listStyle: 'none', gap: '20px', margin: 0, padding: 0 }}>
                <li style={{ cursor: 'pointer' }}>Informações</li>
                <li style={{ cursor: 'pointer' }}>Formulário</li>
                <li style={{ cursor: 'pointer' }}>Últimas Vendas</li>
            </ul>
            <button
                onClick={handleLogout}
                style={{
                    cursor: 'pointer',
                    backgroundColor: 'red', // Fundo vermelho
                    color: '#fff', // Texto branco
                    border: 'none',
                    padding: '5px 10px',
                    borderRadius: '5px', // Cantos arredondados (opcional)
                    transition: 'background-color 0.3s' // Transição suave para hover
                }}
                onMouseOver={(e) => e.target.style.backgroundColor = 'darkred'} // Fundo mais escuro no hover
                onMouseOut={(e) => e.target.style.backgroundColor = 'red'} // Retorna ao fundo vermelho
            >
                Sair
            </button>


        </nav>
    );
};

export default NavBar;
