import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'; // Import necessário para o roteamento
import App from './App.js'; // A extensão .js pode ser omitida
import './index.css';

const container = document.getElementById('root');

// Verifique se o container existe antes de criar a raiz
if (container) {
  const root = createRoot(container);
  root.render(
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  );
} else {
  console.error('Elemento com id "root" não foi encontrado.');
}
