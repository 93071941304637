import React, { useState, useEffect } from 'react';
import LoginForm from './components/LoginForm.jsx';
import NavBar from './components/NavBar.jsx';
import Formulario from './components/Formulario.jsx';
import Informacoes from './components/Informacoes.jsx';
import UltimasVendas from './components/UltimasVendas.jsx';

function App() {
  // Carrega o estado de autenticação do localStorage
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    return localStorage.getItem('isAuthenticated') === 'true';
  });

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
    localStorage.setItem('isAuthenticated', 'true'); // Salva o estado de autenticação no localStorage
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated'); // Remove o estado de autenticação
    localStorage.removeItem('username'); // Remove as credenciais
    localStorage.removeItem('password');
  };

  useEffect(() => {
    if (isAuthenticated) {
      setIsAuthenticated(true);
    }
  }, [isAuthenticated]); // Adiciona `isAuthenticated` como dependência
  

  return (
    <div>
      {!isAuthenticated ? (
        <LoginForm onLoginSuccess={handleLoginSuccess} />
      ) : (
        <>
          <NavBar onLogout={handleLogout} />
          <div style={{ display: 'flex', gap: '20px', paddingTop: '20px' }}>
            <div style={{ flex: 1 }}>
              <Informacoes isAuthenticated={isAuthenticated} />
            </div>
            <div style={{ flex: 1 }}>
              <Formulario isAuthenticated={isAuthenticated} />
            </div>
            <div style={{ flex: 1 }}>
              <UltimasVendas isAuthenticated={isAuthenticated} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default App;
