// import React, { useState, useEffect } from 'react';

// const UltimasVendas = () => {
//   const [vendas, setVendas] = useState([]);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const [isDeleting, setIsDeleting] = useState(false); // Adicionado para controlar requisições simultâneas

//   useEffect(() => {
//     fetchVendas();
//   }, []);

//   const fetchVendas = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:3000/vendas');
//       if (!response.ok) {
//         throw new Error('Erro ao buscar as vendas');
//       }
//       const data = await response.json();

//       // Ordena as vendas por data, da mais recente para a mais antiga
//       const vendasOrdenadas = data.sort((a, b) => new Date(b.data) - new Date(a.data));

//       setVendas(vendasOrdenadas);
//     } catch (error) {
//       console.error('Erro ao buscar vendas:', error);
//       setErrorMessage('Erro ao buscar vendas');
//     }
//   };

//   const handleDeleteVenda = async (index) => {
//     if (isDeleting) return; // Evita múltiplas requisições simultâneas
//     setIsDeleting(true);

//     try {
//       console.log('Excluindo venda com índice:', index + 284); // Log para verificação
//       const response = await fetch(`http://127.0.0.1:3000/vendas/${index + 284}`, {
//         method: 'DELETE',
//       });

//       if (!response.ok) {
//         throw new Error('Erro ao excluir a venda');
//       }

//       setSuccessMessage(`Venda excluída com sucesso.`);
//       await fetchVendas(); // Atualiza a lista após a exclusão
//     } catch (error) {
//       console.error('Erro ao excluir venda:', error);
//       setErrorMessage('Erro ao excluir venda');
//     } finally {
//       setIsDeleting(false); // Libera para novas requisições
//     }
//   };

//   return (
//     <div className="vendas-recentes mt-6 p-6 bg-gradient-to-br from-gray-50 to-white rounded-xl shadow-lg">
//       <h1 className="text-red-500 mb-4 text-xl font-bold text-center">Últimas 5 Vendas</h1>
//       {errorMessage && <p className="text-red-500">{errorMessage}</p>}
//       {successMessage && <p className="text-green-500">{successMessage}</p>}
//       <ul className="space-y-4">
//         {vendas.map((venda, index) => (
//           <li
//             key={index}
//             className="border border-gray-300 p-4 rounded-lg shadow-sm bg-white hover:shadow-md hover:-translate-y-1 transition-transform duration-200"
//           >
//             <p className="mb-1">
//               <strong className="font-semibold text-gray-700">Produto:</strong> {venda.celular}
//             </p>
//             <p className="mb-1">
//               <strong className="font-semibold text-gray-700">Cliente:</strong> {venda.cliente}
//             </p>
//             <p className="mb-1">
//               <strong className="font-semibold text-gray-700">Data:</strong> {new Date(venda.data + 'T00:00:00').toLocaleDateString()}
//             </p>
//             <p className="mb-1">
//               <strong className="font-semibold text-gray-700">Forma de Pagamento:</strong> {venda.formaPagamento}
//             </p>
//             <p className="mb-1">
//               <strong className="font-semibold text-gray-700">Brinde:</strong> {venda.brinde}
//             </p>
//             <button
//               onClick={() => handleDeleteVenda(index)}
//               className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
//               disabled={isDeleting} // Desativa o botão enquanto uma exclusão está em andamento
//             >
//               {isDeleting ? 'Excluindo...' : 'Excluir Venda'}
//             </button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default UltimasVendas;



// import React, { useState, useEffect } from 'react';

// const UltimasVendas = () => {
//   const [vendas, setVendas] = useState([]);
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');

//   useEffect(() => {
//     fetchVendas();
//   }, []);

//   const fetchVendas = async () => {
//     try {
//       const response = await fetch('http://127.0.0.1:3000/vendas');
//       if (!response.ok) {
//         throw new Error('Erro ao buscar as vendas');
//       }
//       const data = await response.json();

//       // Ordena as vendas por data, da mais recente para a mais antiga
//       const vendasOrdenadas = data.sort((a, b) => new Date(b.data) - new Date(a.data));

//       setVendas(vendasOrdenadas);
//     } catch (error) {
//       console.error('Erro ao buscar vendas:', error);
//       setErrorMessage('Erro ao buscar vendas');
//     }
//   };

//   const handleDeleteVenda = async (index) => {
//     try {
//       const response = await fetch(`http://127.0.0.1:3000/vendas/${index}`, {
//         method: 'DELETE',
//       });

//       if (!response.ok) {
//         throw new Error('Erro ao excluir a venda');
//       }

//       setSuccessMessage(`Venda excluída com sucesso.`);
//       fetchVendas(); // Atualiza a lista após a exclusão
//     } catch (error) {
//       console.error('Erro ao excluir venda:', error);
//       setErrorMessage('Erro ao excluir venda');
//     }
//   };

//   return (
//     <div className="vendas-recentes mt-6 p-6 bg-gradient-to-br from-gray-50 to-white rounded-xl shadow-lg">
//       <h1 className="text-red-500 mb-4 text-xl font-bold text-center">Últimas 5 Vendas</h1>
//       {errorMessage && <p className="text-red-500">{errorMessage}</p>}
//       {successMessage && <p className="text-green-500">{successMessage}</p>}
//       <ul className="space-y-4">
//         {vendas.map((venda, index) => (
//           <li
//             key={index}
//             className="border border-gray-300 p-4 rounded-lg shadow-sm bg-white hover:shadow-md hover:-translate-y-1 transition-transform duration-200"
//           >
//             <p className="mb-1">
//               <strong className="font-semibold text-gray-700">Produto:</strong> {venda.celular}
//             </p>
//             <p className="mb-1">
//               <strong className="font-semibold text-gray-700">Cliente:</strong> {venda.cliente}
//             </p>
//               <p className="mb-1">
//                  <strong className="font-semibold text-gray-700">Data:</strong> {new Date(venda.data + 'T00:00:00').toLocaleDateString()}
//               </p>
//             <p className="mb-1">
//               <strong className="font-semibold text-gray-700">Forma de Pagamento:</strong> {venda.formaPagamento}
//             </p>
//             <p className="mb-1">
//               <strong className="font-semibold text-gray-700">Brinde:</strong> {venda.brinde}
//             </p>
//             <button
//               onClick={() => handleDeleteVenda(index)}
//               className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 transition-colors duration-200"
//             >
//               Excluir Venda
//             </button>
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// };

// export default UltimasVendas;


import React, { useState, useEffect } from 'react';

const UltimasVendas = () => {
  const [vendas, setVendas] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const fetchVendas = async () => {
      try {
        const response = await fetch('https://decalcinha-nodejs.vercel.app/vendas');
        if (!response.ok) {
          throw new Error('Erro ao buscar as vendas');
        }
        const data = await response.json();

        // Ordena as vendas para que as últimas inserções apareçam primeiro
        const vendasOrdenadas = data.reverse();

        setVendas(vendasOrdenadas.slice(0, 5)); // Pega apenas as últimas 5 vendas
      } catch (error) {
        console.error('Erro ao buscar vendas:', error);
        setErrorMessage('Erro ao buscar vendas');
      }
    };

    fetchVendas();
  }, []);

  return (
    <div className="vendas-recentes mt-6 p-6 bg-gradient-to-br from-gray-50 to-white rounded-xl shadow-lg">
      <h1 className="text-red-500 mb-4 text-xl font-bold text-center">Últimas 5 Vendas</h1>
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
      <ul className="space-y-4">
        {vendas.map((venda, index) => (
          <li
            key={index}
            className="border border-gray-300 p-4 rounded-lg shadow-sm bg-white hover:shadow-md hover:-translate-y-1 transition-transform duration-200"
          >
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Celular:</strong> {venda.celular}
            </p>
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Cliente:</strong> {venda.cliente}
            </p>
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Data:</strong>
              {new Date(new Date(venda.data).setDate(new Date(venda.data).getDate() + 1)).toLocaleDateString()}
            </p>
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Forma de Pagamento:</strong> {venda.formaPagamento}
            </p>
            <p className="mb-1">
              <strong className="font-semibold text-gray-700">Brinde:</strong> {venda.brinde}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UltimasVendas;
